<template>
  <div class="filter-selection">
    <div class="filter-selection__filter-build">
      <div class="filter-build__dimension">
        <label class="dimension__label">{{
          $t('dashboard.filters.dimension.modal.dimension')
        }}</label>
        <div class="dimension__field">
          <button
            class="field__category"
            @click="$emit('open-member-selection')"
          >
            {{
              //TODO Remove exclamation mark! - DEV 1014
              getMemberCategoryTranslation(
                selectedDimension.meta.category!,
                MemberType.DIMENSION
              )
            }}
            <i class="category__arrow-icon icon-tiny-arrow" />
          </button>
          <AnalyticsMemberSelectionOption
            class="field__member"
            :member="selectedDimension"
            :member-type="MemberType.DIMENSION"
            :has-fixed-width="false"
            :is-clickable="false"
          />
        </div>
      </div>
      <ReflectSelectField
        class="filter-build__operator"
        :top-label="$t('dashboard.filters.dimension.modal.operator')"
        :options="applicableOperators"
        :disabled="valueOptions === null"
        :value="selectedOperator"
        :has-labeled-options="true"
        :hide-selected="false"
        :close-on-select="true"
        :allow-empty="false"
        @input="updateOperator"
      >
        <template #option="{ option }">
          <span class="operator__option">
            <i
              :class="`option__icon icon-${FILTER_OPERATOR_ICON[(option as FilterOperatorOption).value]}`"
            />
            {{ (option as FilterOperatorOption).label }}
          </span>
        </template>
        <template #singleLabel="{ option }">
          <span class="operator__option">
            <i
              :class="`option__icon option__icon--selected icon-${FILTER_OPERATOR_ICON[(option as FilterOperatorOption).value]}`"
            />
            {{ (option as FilterOperatorOption).label }}
          </span>
        </template>
      </ReflectSelectField>
    </div>
    <div class="filter-selection__filter-values">
      <div
        v-if="shouldDisplaySearchAndSelected"
        class="filter-values__available-values"
      >
        <div class="available-values__header">
          <div
            v-if="
              shouldDisplayValueOptions &&
              isSelectedOperatorAllowingMultipleValues
            "
            class="header__select-all"
          >
            <button
              class="select-all__button"
              :disabled="valueOptions === null"
              @click="updateAllOptionsSelection"
            >
              <div
                v-if="selectedValues.length > 0"
                class="button__minus"
              />
            </button>
          </div>
          <div class="header__search">
            <input
              v-model="searchValue"
              :disabled="valueOptions === null"
              class="search__input"
              :placeholder="
                shouldDisplayValueOptions
                  ? $t('dashboard.filters.dimension.modal.searchValue')
                  : $t('dashboard.filters.dimension.modal.writeValue')
              "
              :type="isNumberTypeDimension ? 'number' : 'text'"
              @keyup.enter="addValueOption"
            />
            <i
              v-if="shouldDisplayValueOptions"
              class="search__icon icon-search"
            />
          </div>
        </div>
        <div class="available-values__container">
          <ReflectCheckbox
            v-if="searchValue && !isNotAllowedToAddValueOption"
            class="list__value"
            :value="false"
            :disabled="valueOptions === null"
            :label="searchValue.toString()"
            name="searchValue"
            theme="secondary"
            @toggle="addValueOption"
          />
          <Loader v-if="valueOptions === null" />
          <template v-else>
            <ReflectCheckbox
              v-for="option in filteredValueOptions"
              :key="option.value + getIsValueSelected(option.value)"
              class="list__value"
              :value="getIsValueSelected(option.value)"
              :label="option.label"
              :name="option.label"
              theme="secondary"
              @toggle="toggleOptionSelection(option)"
            />
          </template>
        </div>
      </div>
      <ReflectListBox
        v-if="shouldDisplaySearchAndSelected"
        class="filter-values__selected-values"
        :title="$t('dashboard.filters.dimension.modal.selected')"
        icon="filter"
      >
        <div
          v-for="option in selectedValues"
          :key="option.value"
          class="selected-values__value list__value"
        >
          {{ option.label }}
          <i
            class="value__cross icon-cross"
            @click="unselectOption(option)"
          />
        </div>
      </ReflectListBox>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ALLOWED_BDESE_SCOPE_FILTER_OPERATORS,
  ALLOWED_USER_SCOPE_FILTER_OPERATORS,
  DIMENSION_TITLES,
  FILTER_OPERATORS_BY_DIMENSION_TYPE,
  FILTER_OPERATOR_ICON
} from '~/constants/analytics'
import { analyticsGetOptions } from '~/services/analytics'
import {
  getDimensionFromTitle,
  getMemberCategoryTranslation
} from '~/services/explore'
import { useScopeStore } from '~/stores/scope'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import {
  MemberType,
  type DimensionOption,
  type Filter,
  type ScopeFilter
} from '~/types/analytics'
import { FilterOperator, type Dimension } from '~/types/cube'
import { ScopeType } from '~/types/permissions'
import { formatValue } from '~/services/format'
import { FilterType } from '~/types/filter'
import type { ResultSetItemValue } from '~/types/query'
import { AppContext } from '~/types/context'

type FilterValueOption = {
  label: string
  value: string
}

type FilterOperatorOption = {
  label: string
  value: FilterOperator
}

export default {
  name: 'FilterValuesSelection',
  props: {
    selectedDimension: {
      type: Object as PropType<DimensionOption>,
      required: true
    },
    context: {
      type: String as PropType<AppContext>,
      required: true
    },
    filterType: {
      type: String as PropType<FilterType>,
      required: true
    },
    initialFilter: {
      type: Object as PropType<Filter | ScopeFilter>,
      default: undefined
    }
  },
  emits: ['open-member-selection', 'update-selected-filter'],
  setup() {
    return {
      MemberType,
      getMemberCategoryTranslation,
      FILTER_OPERATOR_ICON
    }
  },
  data(): {
    selectedOperator: FilterOperatorOption
    valueOptions: FilterValueOption[] | null
    selectedValues: FilterValueOption[]
    searchValue: string | number
    addedValueOptions: FilterValueOption[]
  } {
    return {
      selectedOperator: {
        value: this.initialFilter
          ? this.initialFilter.operator
          : FilterOperator.EQUALS,
        label: this.initialFilter
          ? this.$t(`cube.operator.${this.initialFilter.operator}`)
          : this.$t(`cube.operator.${FilterOperator.EQUALS}`)
      },
      valueOptions: null,
      selectedValues: [],
      searchValue: '',
      addedValueOptions: []
    }
  },
  computed: {
    isSetOrNotSetOperator() {
      return (
        this.selectedOperator &&
        [FilterOperator.SET, FilterOperator.NOT_SET].includes(
          this.selectedOperator.value
        )
      )
    },
    shouldDisplaySearchAndSelected() {
      return this.selectedOperator && !this.isSetOrNotSetOperator
    },
    isNotAllowedToAddValueOption() {
      return (
        this.shouldDisplayValueOptions &&
        this.selectedOperator &&
        ![
          FilterOperator.CONTAINS,
          FilterOperator.NOT_CONTAINS,
          FilterOperator.GT,
          FilterOperator.LT,
          FilterOperator.GTE,
          FilterOperator.LTE
        ].includes(this.selectedOperator.value)
      )
    },
    isAllowedToSelectAnAdditionalValueOption() {
      return (
        this.selectedValues.length === 0 ||
        this.isSelectedOperatorAllowingMultipleValues
      )
    },
    isSelectedOperatorAllowingMultipleValues() {
      return (
        this.selectedOperator &&
        ![
          FilterOperator.BEFORE_DATE,
          FilterOperator.AFTER_DATE,
          FilterOperator.LT,
          FilterOperator.GT,
          FilterOperator.LTE,
          FilterOperator.GTE
        ].includes(this.selectedOperator.value)
      )
    },

    shouldDisplayValueOptions() {
      return (
        !this.isNumberTypeDimension ||
        this.selectedDimension.meta.keepOptionsInFilter
      )
    },
    filteredValueOptions() {
      if (this.valueOptions === null) return []

      if (!this.shouldDisplayValueOptions || !this.searchValue.toString()) {
        return this.valueOptions
      }

      return this.valueOptions.filter(option =>
        option.label
          .toLowerCase()
          .includes(this.searchValue.toString().toLowerCase())
      )
    },
    applicableOperators() {
      const operators = FILTER_OPERATORS_BY_DIMENSION_TYPE[
        this.selectedDimension.type
      ].map(operator => ({
        label: this.$t(`cube.operator.${operator}`),
        value: operator
      }))

      return operators.filter(
        operator =>
          this.filterType !== FilterType.SCOPE_FILTER ||
          (this.context === AppContext.USER &&
            this.filterType === FilterType.SCOPE_FILTER &&
            ALLOWED_USER_SCOPE_FILTER_OPERATORS.includes(operator.value)) ||
          (this.context === AppContext.BDESE &&
            this.filterType === FilterType.SCOPE_FILTER &&
            ALLOWED_BDESE_SCOPE_FILTER_OPERATORS.includes(operator.value))
      )
    },
    isNumberTypeDimension() {
      return this.selectedDimension.type === 'number'
    }
  },
  watch: {
    selectedOperator() {
      if (this.valueOptions === null) return

      if (!this.isAllowedToSelectAnAdditionalValueOption) {
        this.selectedValues = this.selectedValues.slice(0, 1)
      }

      if (this.isSetOrNotSetOperator) {
        this.selectedValues = []
      } else if (this.isNotAllowedToAddValueOption) {
        this.valueOptions = this.valueOptions.filter(option =>
          this.addedValueOptions.every(
            addedOption => addedOption.value !== option.value
          )
        )

        this.selectedValues = this.selectedValues.filter(value =>
          this.valueOptions!.some(option => option.value === value.value)
        )
        this.addedValueOptions = []
      }

      if (this.isSetOrNotSetOperator || this.selectedValues.length > 0) {
        this.$emit('update-selected-filter', {
          member: this.selectedDimension.shortTitle,
          operator: this.selectedOperator.value,
          values: this.selectedValues.map(value => value.value)
        })
      }
    },
    selectedValues() {
      if (this.isSetOrNotSetOperator) {
        return
      }

      this.$emit(
        'update-selected-filter',
        this.selectedValues.length > 0
          ? {
              member: this.selectedDimension.shortTitle,
              operator: this.selectedOperator?.value,
              values: this.selectedValues.map(value => value.value)
            }
          : null
      )
    }
  },
  async created() {
    this.selectedValues = this.initialFilter
      ? this.formatValueOptions(
          this.initialFilter.values,
          getDimensionFromTitle(this.initialFilter.member)
        )
      : []

    if (this.shouldDisplayValueOptions) {
      const valueOptions = await this.getValueOptions()
      this.addedValueOptions = this.selectedValues.filter(value =>
        valueOptions.every(option => option.value !== value.value)
      )
      this.valueOptions = [...this.addedValueOptions, ...valueOptions]
    } else {
      this.valueOptions = [...this.selectedValues]
    }
  },
  methods: {
    updateOperator(operator: FilterOperatorOption) {
      this.selectedOperator = operator
    },
    toggleOptionSelection(option: FilterValueOption) {
      if (
        this.isAllowedToSelectAnAdditionalValueOption &&
        this.selectedValues.every(value => value.value !== option.value)
      ) {
        this.selectedValues = [...this.selectedValues, option]
      } else {
        this.selectedValues = this.selectedValues.filter(
          value => value.value !== option.value
        )
      }
    },
    updateAllOptionsSelection() {
      if (this.valueOptions === null) return

      if (this.selectedValues.length === 0) {
        this.selectedValues = [...this.valueOptions]
      } else {
        this.selectedValues = []
      }
    },
    unselectOption(option: FilterValueOption) {
      this.selectedValues = this.selectedValues.filter(
        value => value.value !== option.value
      )
    },
    addValueOption() {
      if (
        !this.searchValue ||
        this.isNotAllowedToAddValueOption ||
        this.valueOptions === null
      ) {
        return
      }

      const formattedValue = formatValue(
        this.searchValue,
        this.selectedDimension.meta.format,
        false
      )

      const existingValueOption = this.valueOptions?.find(
        option => option.label === formattedValue
      )

      if (existingValueOption) {
        this.toggleOptionSelection(existingValueOption)
        this.searchValue = ''
        return
      }

      const newOption = {
        label: formattedValue,
        value: this.searchValue.toString()
      }
      this.valueOptions = [newOption, ...this.valueOptions]
      this.addedValueOptions = [...this.addedValueOptions, newOption]
      this.toggleOptionSelection(newOption)
      this.searchValue = ''
    },
    async getValueOptions() {
      const { selectedPeriod } = useTimeDimensionStore()
      const dateRangeFilter = {
        member: DIMENSION_TITLES.DATE,
        operator: FilterOperator.IN_DATE_RANGE,
        values: selectedPeriod.dateRange
      }
      const { scopeType } = useScopeStore()
      const categories = (
        await analyticsGetOptions(
          this.selectedDimension.shortTitle,
          this.filterType === FilterType.SCOPE_FILTER ? [] : [dateRangeFilter],
          this.filterType === FilterType.SCOPE_FILTER
            ? ScopeType.COMPANY
            : scopeType
        )
      )
        .map(val => Object.values(val)[0])
        .filter(option => option !== null)

      const nonFormattedValueOptions = this.isNumberTypeDimension
        ? (categories.sort((a, b) => (a as number) - (b as number)) as number[])
        : (categories.sort() as string[])

      return this.formatValueOptions(
        nonFormattedValueOptions,
        this.selectedDimension
      )
    },
    getIsValueSelected(value: string) {
      return this.selectedValues.some(option => option.value === value)
    },
    formatValueOptions(
      valueOptions: ResultSetItemValue[],
      dimension: Dimension
    ) {
      return valueOptions.map(option => ({
        label: formatValue(option, dimension.meta.format, false),
        value: option!.toString()
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-selection {
  width: $selection-container-width;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  &__filter-build {
    @include font-text;
    @include font-size($font-size-mini);
    display: flex;
    align-items: center;
    margin-bottom: $margin-regular;
    column-gap: $margin-small;

    .filter-build {
      &__dimension {
        flex: 1;

        .dimension {
          &__label {
            display: block;
            color: $text-secondary;
            margin-bottom: $margin-tiny;
          }

          &__field {
            box-sizing: border-box;
            height: $field-height;
            border: $border-ternary 1px solid;
            border-radius: 8px;
            display: flex;
            align-items: center;
            overflow: hidden;

            .field {
              &__category {
                @include font-text;
                @include font-size($font-size-regular);
                padding: 0 $margin-intermediate 0 $margin-small;
                height: 100%;
                cursor: pointer;
                background-color: $bg-primary;
                color: $text-primary;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 200px;

                .category {
                  &__arrow-icon {
                    @include font-icon;
                    @include font-size(10px);
                  }
                }
              }

              &__member {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }

      &__operator {
        //We need this to prevent the select field from being on 2 lines
        width: 244px;

        .operator {
          &__option {
            display: flex;
            align-items: center;

            .option__icon {
              @include font-icon;
              @include font-size($font-size-intermediate);
              margin-right: $margin-tiny;

              &--selected {
                color: $text-tertiary;
              }
            }
          }
        }
      }
    }
  }

  &__filter-values {
    display: flex;
    column-gap: $margin-intermediate;
    flex: 1;
    overflow: hidden;

    .filter-values {
      &__available-values {
        border: 1px solid $border-ternary;
        border-radius: 8px;
        width: 100%;
        overflow: hidden auto;
        height: 100%;

        .available-values {
          &__header {
            height: 48px;
            display: flex;
            text-transform: uppercase;
            background-color: $bg-quinary;
            border-bottom: 1px solid $border-ternary;
            box-sizing: border-box;
            position: sticky;
            top: 0;
            z-index: 1;

            .header {
              &__select-all {
                padding: $margin-regular;
                padding-left: $margin-intermediate;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid $border-ternary;

                .select-all {
                  &__button {
                    border: 1px solid $bg-tertiary;
                    border-radius: 2px;
                    width: 17px;
                    height: 17px;
                    position: relative;
                    cursor: pointer;

                    .button__minus {
                      content: '';
                      display: block;
                      width: 9px;
                      height: 2px;
                      background-color: $bg-tertiary;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      border-radius: 1px;
                    }
                  }
                }
              }

              &__search {
                position: relative;
                background-color: $bg-primary;
                width: 100%;

                .search {
                  &__input {
                    border: none;
                    outline: none;
                    padding: $margin-small;
                    @include font-text;
                    @include font-size($font-size-regular);
                    color: $text-secondary;
                    background-color: inherit;
                    height: 100%;
                    box-sizing: border-box;
                    width: 100%;

                    &::placeholder {
                      color: $text-tertiary;
                    }
                  }

                  &__icon {
                    position: absolute;
                    top: 50%;
                    right: $margin-small;
                    transform: translateY(-50%);
                    @include font-icon;
                    @include font-size($font-size-regular);
                    color: $text-tertiary;
                  }
                }
              }
            }
          }

          &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }

      &__selected-values {
        width: 100%;

        .selected-values {
          &__value {
            justify-content: space-between;
            box-sizing: border-box;

            .value {
              &__cross {
                @include font-icon;
                @include font-size(12px);
                color: $text-secondary;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .list__value {
    padding: $margin-small $margin-intermediate;
    @include font-text;
    @include font-size($font-size-mini);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;

    &:nth-child(even) {
      background-color: $bg-quaternary;
    }

    &:nth-child(odd) {
      background-color: $bg-secondary;
    }
  }
}
</style>
